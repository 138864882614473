<template>
    <div>
        <h1 class="page-title">This page doesn't seem to exist.</h1>
        <p class="page-sub-title">It looks like the link pointing here was faulty.</p>
    </div>
</template>

<script>
export default {
    name: "NotFound",
};
</script>

<style scoped>
/* Add your styles here */
.page-title {
    color: #00aef0;
    font-weight: bold;
    margin-top: 300px;
    font-size: 60px;
}
.page-sub-title {
    color: black;
    font-weight: Bold;
    font-size: 1.5rem;
    margin-bottom: 200px;
}
</style>
