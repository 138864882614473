<template>
    <ToastNotification :message="message" :isVisible="isVisible" :bgColor="bgc" />
    <MoreDetails :toggleDetails="toggleDetails" :showDetails="showDetails" />
    <UpgradeRequest :showUpgrade="showUpgrade" :toggleUpgrade="toggleUpgrade" />
    <ScreenerQst v-if="showScreener" :toggleScreener="toggleScreener" />
    <CreateAssesment v-if="showCreateAssesment" :toggleCreateAssesment="toggleCreateAssesment" />
    <div class="flex items-center">
        <div class="newAssessWrapper pb-10">
            <div class="w-full flex justify-between">
                <!-- <div class="flex"> -->
                <button class="backBtn bg-black/5 text-gray-700 hover:bg-black/10 mx-3 flex items-center py-2 px-3 font-md" @click="previousStep">
                    <font-awesome-icon :icon="['fas', 'angle-left']" class="mr-2" />
                    Previous
                </button>
                <div class="mx-4 flex flex-col text-center">
                    <h2 class="projData">
                        {{ project.name ? project.name : "Untitled" }}
                        {{ project.seniority && " - " + project.seniority }}
                        {{ project.jobTitle && " - " + project.jobTitle }}
                    </h2>
                    <div class="flex justify-center" style="color: #2196f3">
                        <div class="flex items-center">
                            <font-awesome-icon :icon="['far', 'file-lines']" class="mx-2" />
                            <p>
                                {{ placeholders.filter((assessment) => assessment.assessment !== null).length }}
                                tests
                            </p>
                        </div>
                        <div class="flex mx-2 items-center">
                            <font-awesome-icon :icon="['far', 'clock']" class="mx-2" />
                            <p>{{ totalDration }} minutes</p>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
                <div class="flex items-center">
                    <button class="nextStep shadow" @click="nextStep" v-if="this.step < 4">Next step</button>
                    <button class="nextStep shadow" @click="PostJob" v-else-if="!this.isLoading">Finish</button>
                    <button class="nextStep shadow flex items-center justify-center" v-else-if="this.isLoading">
                        <LoaderComponent />
                    </button>
                </div>
            </div>
            <div class="flex flex-row items-center justify-between relative mt-14 mb-10">
                <span
                    v-for="(step, index) in steps"
                    :key="index"
                    class="relative text-white w-10 h-10 rounded-full flex justify-center items-center before:absolute before:bg-transparent before:border-2 before:border-white before:w-8 before:h-8 before:left-1/2 before:top-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:rounded-full"
                    :class="`after:absolute after:-bottom-8 after:left-1/2 after:-translate-x-1/2 after:text-slate-700 after:content-['${step.title}'] ${
                        this.step >= step.number ? 'bg-c' : 'bg-[#A7C6E3]'
                    } `"
                    @click="jumpTostep(step.number)"
                    >{{ step.number < this.step ? "&#10004;" : step.number }}
                    <h1 class="absolute -bottom-8 left-1/2 -translate-x-1/2 text-sm font-bold text-slate-700 w-fit whitespace-normal overflow-visible text-center">
                        {{ step.title }}
                    </h1>
                </span>
                <span
                    v-for="(step, index) in steps"
                    :key="index"
                    class="absolute h-0.5 top-1/2 -translate-y-1/2"
                    :class="index == steps.length - 1 ? 'hidden' : '' + ' ' + this.step > step.number ? 'bg-[#2371b6]' : 'bg-[#A7C6E3]'"
                    :style="{ width: `${lineWidth()}%`, left: `${(lineWidth() + 8) * index + 7}%` }"
                ></span>
            </div>

            <div class="w-full">
                <div class="bg-[#fff] rounded-md" v-show="step === 1">
                    <form action="POST" @submit.prevent="onSubmit" ref="form1" class="grid grid-cols-2 gap-4 items-center pt-10 px-20 pb-10">
                        <!-- ... your form content for section 1 ... -->
                        <div class="input_group">
                            <Popper :content="$t('project name tooltip')" placement="right" :hover="true">
                                <font-awesome-icon class="text-[18px] text-[#7D8FB3]" :icon="['far', 'circle-question']" />
                            </Popper>
                            <input type="text" id="project_name" name="project_name" v-model="project.name" @focus="isInputFocused = true" required autocomplete="off" />
                            <label class="floating_label" :class="{ active: project.name }" for="project_name">
                                <img loading="lazy" decoding="async" src="../assets/Images/icons/job_icon.svg" alt="" />
                                {{ $t("Project name") }}
                            </label>
                            <span v-if="requiredFields.project_name" class="err_msg">{{ requiredFields.project_name }} </span>
                        </div>

                        <div class="input_group">
                            <div class="fixed top-0 left-0 w-full h-full" v-if="showJobCat" @click="toggleDropdownCat"></div>
                            <Popper :content="$t('category tooltip')" placement="top" :hover="true">
                                <font-awesome-icon class="text-[18px] text-[#7D8FB3]" :icon="['far', 'circle-question']" />
                            </Popper>
                            <div class="custom-select w-[100%] bg-[#fff]" style="z-index: 3; border-radius: 8px" @click="toggleDropdownCat">
                                <div class="w-full selected-option flex justify-between items-center">
                                    <!-- <p v-else class="flex items-center"><img loading="lazy"  decoding="async" class="mr-2" src="../assets/Images/icons/Archeive.svg" alt="" />Job Role</p> -->
                                    <!-- <input type="text" placeholder="Search Job Role" class="w-full p-2" v-model="searchJobRole" @click.stop /> -->
                                    <!-- <div class="jobPosSearch"> -->
                                    <input
                                        type="text"
                                        id="jobRole"
                                        @click.stop="toggleDropdownCat"
                                        @click="toggleDropdownCat"
                                        autocomplete="off"
                                        name="jobRole"
                                        v-model="searchJobCat"
                                        @focus="
                                            () => {
                                                toggleDropdownCat();
                                                isInputFocused = true;
                                            }
                                        "
                                        required
                                    /><label class="required floating_label" :class="{ active: searchJobCat }" for="jobRole">
                                        <font-awesome-icon class="text-[#2196f3] mx-3" :icon="['fas', 'briefcase']" />
                                        Job Category</label
                                    >
                                    <div class="absolute right-5 top-[50%]" style="transform: translateY(-50%)">
                                        <font-awesome-icon :icon="['fas', 'chevron-down']" />
                                    </div>
                                    <!-- </div> -->
                                </div>
                                <ul class="options" v-if="showJobCat">
                                    <li
                                        v-for="(jobPos, index) in categories"
                                        :key="index"
                                        @click.stop
                                        @click="
                                            () => {
                                                toggleDropdownCat();
                                                selectJobCat(jobPos);
                                            }
                                        "
                                    >
                                        {{ jobPos }}
                                    </li>
                                </ul>
                            </div>
                            <!-- <span v-if="requiredFields.job_title" class="err_msg">{{ requiredFields.job_title }} </span> -->
                        </div>

                        <div class="input_group">
                            <Popper class="w-[10%]" :content="$t('job position tooltip')" placement="right" :hover="true">
                                <font-awesome-icon class="text-[18px] text-[#7D8FB3]" :icon="['far', 'circle-question']" />
                            </Popper>
                            <div class="fixed top-0 left-0 w-full h-full" v-if="showJobTitles" @click="toggleDropdown"></div>
                            <div class="custom-select !z-2000 w-[100%] bg-[#fff]" style="z-index: 3; border-radius: 8px" @click="toggleDropdown">
                                <div class="w-full selected-option flex justify-between items-center">
                                    <input
                                        type="text"
                                        id="jobRole"
                                        @click.stop="toggleDropdown"
                                        @click="toggleDropdown"
                                        name="jobRole"
                                        v-model="searchJobRole"
                                        autocomplete="off"
                                        @focus="
                                            () => {
                                                toggleDropdown();
                                                isInputFocused = true;
                                            }
                                        "
                                        required
                                    />
                                    <label class="floating_label" :class="{ active: searchJobRole }" for="jobRole">
                                        <font-awesome-icon class="text-[#2196f3] mx-3" :icon="['fas', 'briefcase']" />
                                        {{ $t("Job Role") }}</label
                                    >
                                    <div class="absolute right-5 top-[50%]" style="transform: translateY(-50%)">
                                        <font-awesome-icon :icon="['fas', 'chevron-down']" />
                                    </div>
                                </div>
                                <ul class="options" v-if="showJobTitles">
                                    <li
                                        v-for="(jobPos, index) in searchedJob"
                                        :key="index"
                                        @click.stop
                                        @click="
                                            () => {
                                                toggleDropdown();
                                                selectJobPosition(jobPos);
                                            }
                                        "
                                    >
                                        {{ jobPos.title }}
                                    </li>
                                </ul>
                            </div>
                            <span v-if="requiredFields.job_title" class="err_msg">{{ requiredFields.job_title }} </span>
                        </div>
                        <div class="input_group select">
                            <!-- <select style="font-weight: 500; font-size: 18px; line-height: 32px; color: #05152e" name="Seniority" id="" class="selectStatus" v-model="project.seniority">
                            <option value="">Seniority</option>
                            <option v-for="(seniority, index) in seniorities" :key="index" :value="seniority">{{ seniority }}</option>
                        </select> -->
                            <Popper :content="$t('job seniority tooltip')" placement="bottom" :hover="true">
                                <font-awesome-icon class="text-[18px] text-[#7D8FB3]" :icon="['far', 'circle-question']" />
                            </Popper>
                            <div class="fixed top-0 left-0 w-full h-full" v-if="showSeniority" @click="toggleSeniority"></div>
                            <div class="custom-select w-[100%] bg-[#fff] relative" style="border-radius: 8px" @click="toggleSeniority">
                                <div class="w-full h-[3rem] selected-option flex justify-between items-center">
                                    <input
                                        type="text"
                                        id="jobRole"
                                        @click.stop="toggleSeniority"
                                        @click="toggleSeniority"
                                        autocomplete="off"
                                        name="jobRole"
                                        v-model="project.seniority"
                                        @focus="
                                            () => {
                                                toggleSeniority();
                                                // isInputFocused = true;
                                            }
                                        "
                                        required
                                    />
                                    <label class="required floating_label" :class="{ active: this.project.seniority }" for="job_seniority"
                                        ><img loading="lazy" decoding="async" src="../assets/Images/icons/seniority_icon.svg" alt="" />
                                        {{ $t("Job Seniority") }}
                                    </label>

                                    <div class="absolute right-5 top-[50%]" style="transform: translateY(-50%)">
                                        <font-awesome-icon :icon="['fas', 'chevron-down']" />
                                    </div>
                                    <!-- </div> -->
                                </div>

                                <ul class="options" v-if="showSeniority">
                                    <li
                                        v-for="(seniority, index) in seniorities"
                                        :key="index"
                                        @click.stop
                                        @click="
                                            () => {
                                                toggleSeniority();
                                                selectSeniority(seniority);
                                            }
                                        "
                                    >
                                        {{ seniority }}
                                    </li>
                                </ul>
                            </div>
                            <span v-if="requiredFields.job_seniority" class="err_msg">{{ requiredFields.job_seniority }}</span>
                        </div>
                    </form>
                    <!-- <div v-if="this.project.jobTitle">
                        <div class="flex items-center gap-3">
                            <Popper :content="$t('created by tooltip')" placement="bottom" :hover="true">
                                <font-awesome-icon class="text-[18px] text-[#7D8FB3]" :icon="['far', 'circle-question']" />
                            </Popper>
                            <h3 class="text-[16px] font-[500] text-[#7D8FB3]">Created By</h3>
                        </div>
                        <div class="flex gap-4 w-full">
                            <div @click="selectRadio('essentials')" class="radio-container shadow-card w-1/2">
                                <div @click="selectRadio('essentials')" :class="{ checked: selectedOption === 'essentials' }" class="relative">
                                    <input type="radio" name="option" :checked="selectedOption === 'essentials'" class="custom-radio" />
                                    <label :for="option" class="mx-5">Using an Essential Skills Template</label>
                                </div>
                                <p class="text-[14px] my-6">Evaluate essential skills applicable across job roles and industries.</p>
                                <p @click="toggleDetails" class="text-[#2196f3] font-[500] cursor-pointer hover:underline">See details</p>
                            </div>
                            <div
                                @click="
                                    () => {
                                        if (this.Store.premium) {
                                            selectRadio('custom');
                                        } else {
                                            toggleUpgrade();
                                        }
                                    }
                                "
                                class="radio-container shadow-card w-1/2"
                                :class="{ disabled: !this.Store.premium }"
                            >
                                <div :class="{ checked: selectedOption === 'custom' }" class="relative flex items-center">
                                    <input type="radio" name="option" :checked="selectedOption === 'custom'" class="custom-radio" />
                                    <label :for="option" class="mx-5 flex gap-4 items-center" :class="{ 'text-[#A4A09E]': !this.Store.premium }"
                                        >Build your own
                                        <div
                                            v-if="!this.Store.premium"
                                            class="text-[#FFC300] w-[30px] h-[30px] rounded-[50%] text-xl flex justify-center items-center bg-white"
                                            style="border: 3px solid #ffc300"
                                        >
                                            <font-awesome-icon :icon="['far', 'gem']" />
                                        </div>
                                    </label>
                                </div>
                                <p class="text-[14px] my-6">Select your own tests and custom questions.</p>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div v-show="step === 2">
                    <div class="w- full mb-[2rem] mt-[3rem] flex items-center justify-end" style="">
                        <ButtonComponent
                            :action="
                                () => {
                                    toggleCreateAssesment();
                                }
                            "
                            intent="primary"
                        >
                            Build your Assessment
                        </ButtonComponent>
                    </div>
                    <div v-if="loadingAssess" class="w-full h-[300px] flex justify-center items-center">
                        <NewAssessLoader />
                    </div>
                    <div v-else>
                        <div class="myTests">
                            <EmptyTest
                                v-for="(placeholder, index) in placeholders"
                                :key="index"
                                :index="index"
                                :assessment="placeholder.assessment"
                                :handleDrop="handleDrop"
                                :handleDragOver="handleDragOver"
                                :removeAssessment="removeAssessment"
                            />
                        </div>

                        <div class="my-5">
                            <NewAssessLib
                                :removeAssessment="removeAssessment"
                                :recommendations="this.recommendations"
                                :selectedOption="selectedOption"
                                :placeholders="placeholders"
                                :addNewAssessment="addAssessment"
                            />
                        </div>
                    </div>
                </div>
                <div v-show="step === 3">
                    <div class="w- full mb-[2rem] mt-[3rem] flex items-center justify-end" style="">
                        <ButtonComponent
                            :action="
                                () => {
                                    toggleScreener();
                                }
                            "
                            intent="primary"
                        >
                            {{ this.Store.screenersQst.length > 0 ? "Update Screeners" : "Add Screeners" }}
                        </ButtonComponent>
                    </div>
                    <h1 class="screenersTitle my-6">My Screeners:</h1>
                    <div v-if="this.Store.screenersQst.length > 0" class="w-full flex flex-col justify-start items-center shadow-inner mb-10">
                        <div
                            class="font-bold flex flex-row justify-start items-center w-full text-slate-700 text-base text-start border-b border-slate-300 rounded-t bg-white shadow-[0_4px_6px_1px_rgba(0,0,0,10%)]"
                        >
                            <div class="h-12 p-4 w-1/12 flex items-center">TYPE</div>
                            <!-- <div class="h-12 p-4 w-1/12 flex items-center">TIME</div> -->
                            <div class="h-12 p-4 w-10/12 flex items-center">QUESTION</div>
                        </div>
                        <div
                            v-for="(qst, index) in this.Store.screenersQst"
                            :key="index"
                            class="group flex flex-col justify-start items-center w-full text-slate-700 text-base text-start border-b border-slate-300 bg-white"
                            :class="qst.showMore ? 'my-4 shadow-inner rounded-md' : 'hover:bg-[#A7C6E3]'"
                        >
                            <div class="relative flex flex-row justify-start items-center w-full h-12 text-sm font-light overflow-hidden">
                                <div class="p-4 w-1/12 text-xs">{{ qst.type }}</div>
                                <!-- <div class="p-4 w-1/12 text-xs"><font-awesome-icon :icon="['far', 'clock']" class="w-3 h-3 font-extralight" />{{ " " + qst.time }} mins</div> -->
                                <div class="p-4 w-96 whitespace-nowrap">{{ qst.question }}</div>
                            </div>
                            <div class="w-full flex flex-col justify-center items-start overflow-hidden px-10 h-0" :class="qst.showMore ? 'qstDown py-10' : ''">
                                <p class="w-1/2 my-4 text-base font-normal text-slate-700">{{ qst.description }}</p>
                                <p class="w-1/2 my-4 text-base font-normal text-slate-700">{{ qst?.tip }}</p>
                            </div>
                        </div>
                    </div>

                    <h1 class="screenersTitle my-6">Qustom Questions:</h1>
                    <div class="w-full flex flex-col items-center justify-between">
                        <div class="w-full text-base font-light text-slate-700 bg-[#beddfa] p-1 rounded mb-4 flex flex-row items-center justify-between">
                            <span class="h-full px-4">You can add up to 5 custom questions to your assessment from our library, or create your own</span>
                            <button class="h-full p-3 px-4 bg-white rounded-sm" @click="defineType('Essay')">Create custom question</button>
                        </div>

                        <div class="w-full flex flex-col justify-start items-center shadow-inner">
                            <div
                                class="font-bold flex flex-row justify-start items-center w-full text-slate-700 text-base text-start border-b border-slate-300 rounded-t bg-white shadow-[0_4px_6px_1px_rgba(0,0,0,10%)]"
                            >
                                <div class="h-12 p-4 w-1/12 flex items-center">TYPE</div>
                                <div class="h-12 p-4 w-1/12 flex items-center">TIME</div>
                                <div class="h-12 p-4 w-10/12 flex items-center">QUESTION</div>
                            </div>
                            <div
                                v-for="(qst, index) in selectedQuestions"
                                :key="index"
                                class="group flex flex-col justify-start items-center w-full text-slate-700 text-base text-start border-b border-slate-300 bg-white"
                                :class="qst.showMore ? 'my-4 shadow-inner rounded-md' : 'hover:bg-[#A7C6E3]'"
                            >
                                <div class="relative flex flex-row justify-start items-center w-full h-12 text-sm font-light overflow-hidden">
                                    <div class="p-4 w-1/12 text-xs">{{ qst.category }}</div>
                                    <div class="p-4 w-1/12 text-xs"><font-awesome-icon :icon="['far', 'clock']" class="w-3 h-3 font-extralight" />{{ " " + qst.time }} mins</div>
                                    <div class="p-4 w-96 whitespace-nowrap">{{ qst.question }}</div>
                                    <div
                                        class="absolute top-0 right-0 h-full flex flex-row gap-3 justify-end items-center bg-white text-slate-500 shadow-[-20px_0_6px_1px_rgba(255,255,255,80%)] px-3"
                                        :class="qst.showMore ? '' : 'group-hover:bg-[#A7C6E3] group-hover:shadow-none'"
                                    >
                                        <button
                                            @click="moveQstUp(index)"
                                            class="w-4 h-4 opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease"
                                            :class="qst.shoxMore ? 'opacity-100' : 'opactity-0'"
                                        >
                                            <font-awesome-icon :icon="['fas', 'angle-up']" />
                                        </button>
                                        <button
                                            @click="moveQstDown(index)"
                                            class="w-4 h-4 opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease"
                                            :class="qst.shoxMore ? 'opacity-100' : 'opactity-0'"
                                        >
                                            <font-awesome-icon :icon="['fas', 'angle-down']" />
                                        </button>

                                        <button
                                            @click="deleteSelectedQst(index)"
                                            class="w-4 h-4 opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease"
                                            :class="qst.showMore ? 'opacity-100' : 'opactity-0'"
                                        >
                                            <font-awesome-icon :icon="['far', 'trash-can']" />
                                        </button>
                                    </div>
                                </div>
                                <div class="w-full flex flex-col justify-center items-start overflow-hidden px-10 h-0" :class="qst.showMore ? 'qstDown py-10' : ''">
                                    <p class="w-1/2 my-4 text-base font-normal text-slate-700">{{ qst.description }}</p>
                                    <p class="w-1/2 my-4 text-base font-normal text-slate-700">{{ qst?.tip }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <AddCustomQuestion :isShown="isShown" :closePanel="closePanel" :type="question_type" :addQst="addQst" />

                    <div class="my-5">
                        <CustomQuestionLib :selectedQuestions="selectedQuestions" :addNewAssessment="addAssessment" :addQst="addQst" :deleteSelectedQst="deleteSelectedQst" />
                    </div>
                </div>
                <div v-show="step === 4">
                    <ConfirmNewAssessment
                        :moveQstUp="moveQstUp"
                        :moveQstDown="moveQstDown"
                        :deleteQst="deleteSelectedQst"
                        :customQst="selectedQuestions"
                        :moveTestUp="moveTestUp"
                        :moveDownObject="moveDownObject"
                        :removeAssessment="removeAssessment"
                        :assessments="placeholders"
                        :setScore="setScore"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EmptyTest from "@/components/EmptyTest.vue";
import AddCustomQuestion from "@/components/AddCustomQuestion.vue";
import NewAssessLib from "@/components/NewAssessLib.vue";
import CustomQuestionLib from "@/components/CustomQuestionLib.vue";
import ConfirmNewAssessment from "@/components/ConfirmNewAssessment.vue";
import ToastNotification from "@/components/ToastNotification";
import LoaderComponent from "@/components/LoaderComponent.vue";
import CreateAssesment from "../components/CreateAssesment.vue";
import ScreenerQst from "../components/ScreenerQst.vue";
// import NewAssessLoader from "@/components/NewAssessLoader";
import NewAssessLoader from "@/components/NewAssessLoader";
import MoreDetails from "@/components/NewAssessment/MoreDetails.vue";
import UpgradeRequest from "@/components/NewAssessment/UpgradeRequest.vue";
import { useStore } from "../store/index";
import ButtonComponent from "@/components/ReusableComponents/ButtonComponent.vue";
import axios from "axios";
export default {
    name: "NewAssessement",
    components: {
        CreateAssesment,
        ScreenerQst,
        ButtonComponent,
        EmptyTest,
        AddCustomQuestion,
        NewAssessLib,
        ConfirmNewAssessment,
        CustomQuestionLib,
        ToastNotification,
        LoaderComponent,
        NewAssessLoader,
        MoreDetails,
        UpgradeRequest,
    },
    data() {
        return {
            showScreener: false,
            showUpgrade: false,
            showDetails: false,
            types: [
                { name: "Essay", icon: "../assets/essay.svg" },
                { name: "Multiple-choice", icon: "../assets/multiple-choice.svg" },
            ],
            showCreateAssesment: false,
            isLoading: false,
            question_type: "",
            categories: [
                "All",
                "Information Technology",
                "Software Development",
                // "Healthcare and Medical Services",
                "Sales and Marketing",
                "Education and Training",
                "Finance and Accounting",
                "Engineering",
                "Customer Service and Support",
                "Human Resources",
                "Manufacturing and Production",
                "Management and Leadership",
            ],
            isShown: false,
            selectedQuestions: [],
            project_id: "",
            customAssessment: {
                company: "",
                name: "",
                category: "",
                questions_list: [],
            },
            step: 1,
            project: {
                name: "",
                jobTitle: "",
                seniority: "",
                min_score: 70,
                assessments: [],
            },
            requiredFields: {
                project_name: "",
                job_title: "",
                job_seniority: "",
            },
            placeholders: [
                { id: 1, assessment: null },
                { id: 2, assessment: null },
                { id: 3, assessment: null },
                { id: 4, assessment: null },
                { id: 5, assessment: null },
            ],
            seniorities: ["Internship", "Entry Level", "Junior", "Senior", "VP", "Executive"],
            showJobTitles: false,
            showJobCat: false,
            jobPositions: [],
            searchedJob: [],
            recommendations: [],
            searchJobRole: "",
            searchJobCat: "",
            message: "",
            isVisible: false,
            bgc: "",
            loadingAssess: true,
            steps: [
                { number: 1, title: "Create project" },
                { number: 2, title: "Select tests" },
                { number: 3, title: "Add questions" },
                { number: 4, title: "Review & configure" },
            ],
            showSeniority: false,
            selectedOption: "essentials",
        };
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    methods: {
        toggleScreener() {
            this.showScreener = !this.showScreener;
        },
        toggleUpgrade() {
            this.showUpgrade = !this.showUpgrade;
        },
        toggleDetails() {
            this.showDetails = !this.showDetails;
        },
        selectRadio(optionValue) {
            // When an option is clicked, update the selectedOption value
            this.selectedOption = optionValue;
        },
        toggleCreateAssesment() {
            this.showCreateAssesment = !this.showCreateAssesment;
        },
        setScore(score) {
            this.project.min_score = score;
        },
        toggleSeniority() {
            this.showSeniority = !this.showSeniority;
            this.showJobTitles = false;
            this.showJobCat = false;
        },
        selectSeniority(seniority) {
            this.project.seniority = seniority;
        },

        defineType(type) {
            this.question_type = type;
            this.isShown = true;
        },
        closePanel() {
            this.isShown = false;
        },
        addQst(qst) {
            this.selectedQuestions.push({ ...qst, showMore: false, added: true });
        },
        lineWidth() {
            return 100 / (this.steps.length - 1) - 10;
        },
        showMore(id) {
            this.selectedQuestions[id].showMore = !this.selectedQuestions[id].showMore;
        },
        deleteSelectedQst(id) {
            this.selectedQuestions.splice(id, 1);
        },
        moveQstUp(id) {
            if (id === 0) return;
            const prev = this.selectedQuestions[id - 1];
            this.selectedQuestions[id - 1] = this.selectedQuestions[id];
            this.selectedQuestions[id] = prev;
        },
        moveQstDown(id) {
            if (id === this.selectedQuestions.length - 1) return;
            const prev = this.selectedQuestions[id + 1];
            this.selectedQuestions[id + 1] = this.selectedQuestions[id];
            this.selectedQuestions[id] = prev;
        },
        toggleDropdown() {
            this.showJobTitles = !this.showJobTitles;
            this.showJobCat = false;
            this.showSeniority = false;
        },
        toggleDropdownCat() {
            this.showJobCat = !this.showJobCat;
            this.showSeniority = false;
            this.showJobTitles = false;
        },
        selectJobPosition(jobPos) {
            this.project.jobTitle = jobPos.title;
            this.searchJobRole = jobPos.title;
            this.recommendations = jobPos.recommended;
        },
        selectJobCat(jobPos) {
            //this.project.jobTitle = jobPos.title;
            this.searchJobCat = jobPos;
            this.loading = true;
            axios
                //https://server.go-platform.com/
                .get(`https://server.go-platform.com/jobsPages/jobs`, {
                    params: {
                        category: this.searchJobCat,
                    },
                })
                .then((res) => {
                    //alert(res.data);
                    this.searchedJob = res.data.jobPositions;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        validateForm() {
            // Reset requiredFields object
            this.requiredFields = {};

            // Check for required fields
            if (!this.project.name) this.requiredFields.project_name = "Project name is required";
            if (!this.project.jobTitle) this.requiredFields.job_title = "Job title is required";
            if (!this.project.seniority) this.requiredFields.job_seniority = "Job seniority is required";
            // Check if all required fields are filled
            return Object.keys(this.requiredFields).length === 0;
        },
        nextStep() {
            if (this.step < 4) {
                if (this.step === 1) {
                    if (this.validateForm()) {
                        this.loadingAssess = true;
                        this.step++;
                        setTimeout(() => {
                            this.loadingAssess = false;
                        }, 3600);
                        axios
                            .get(`https://server.go-platform.com/jobPositions/getRecommended?title=${this.project.jobTitle}&essentials=${this.selectedOption}`, {
                                withCredentials: true,
                            })
                            .then((res) => {
                                console.log("RECOMMENDATIONS", res.data);
                                this.recommendations = res.data.recommendedIds;
                                setTimeout(() => {
                                    this.loadingAssess = false;
                                }, 2500);
                            })
                            .catch((error) => {
                                console.log(error);
                                setTimeout(() => {
                                    this.loadingAssess = false;
                                }, 2500);
                            });
                    }
                } else if (this.step === 2) {
                    if (this.placeholders[0]?.assessment !== null) {
                        this.step++;
                        const customAssessment = this.project.assessments.find((assessment) => assessment.category === "Custom");
                        this.selectedQuestions = customAssessment ? customAssessment.questions_list : [];
                    }
                } else if (this.step === 3) this.step++;
            }
            if (this.step === 4) {
                console.log();
            }
        },
        jumpTostep(step) {
            switch (step) {
                case 1:
                    this.step = 1;
                    break;
                case 2:
                    if (this.validateForm()) this.step = 2;
                    break;
                case 3:
                    if (this.validateForm() && this.placeholders[0]?.assessment !== null) this.step = 3;
                    break;
                case 4:
                    if (this.validateForm() && this.placeholders[0]?.assessment !== null) this.step = 4;
                    break;
                default:
                    break;
            }
        },
        previousStep() {
            if (this.step > 1) {
                this.step--;
            }
        },
        handleDragOver(index, event) {
            // Prevent default behavior to allow drop
            event.preventDefault();

            // Get the target element
            const targetElement = event.currentTarget;

            // Get the position of the mouse pointer relative to the target element
            const rect = targetElement.getBoundingClientRect();
            const offsetX = event.clientX - rect.left;
            // const offsetY = event.clientY - rect.top;

            // If the mouse pointer is more than 50% over the target element,
            // swap the cards
            if (offsetX > rect.width / 2) {
                // Swap the assessments in the array
                const draggedIndex = event.dataTransfer.getData("text/plain");
                const draggedAssessment = this.placeholders[draggedIndex];

                // Swap the assessments if the target index is not the same as the dragged index
                if (index !== draggedIndex) {
                    this.placeholders[draggedIndex] = this.placeholders[index];
                    this.placeholders[index] = draggedAssessment;
                }
            }
        },
        handleDrop(index, event) {
            // Prevent default behavior to allow drop
            event.preventDefault();

            // Get the index of the dragged item from the drag data
            const draggedIndex = event.dataTransfer.getData("text/plain");
            // Swap the assessments in the array
            const draggedAssessment = this.placeholders[draggedIndex];
            this.placeholders[draggedIndex] = this.placeholders[index];
            this.placeholders[index] = draggedAssessment;
        },
        addAssessment(assessment) {
            if (assessment.label == "premium" && !this.Store.premium && assessment.rating !== "essential") {
                this.message = "You need to upgrade to premium to add this test!";
                this.isVisible = true;
                this.bgc = "red";
                setTimeout(() => {
                    this.isVisible = false;
                }, 5000);
                return;
            }

            const emptyPlaceholder = this.placeholders.find((placeholder) => placeholder?.assessment === null);
            if (emptyPlaceholder) {
                emptyPlaceholder.assessment = assessment;

                // Calculate time attribute based on the logic provided
                const time = assessment.questions_nbr > 25 ? parseInt((20 * 35) / 60) : parseInt((assessment.questions_nbr * 35) / 60);
                emptyPlaceholder.assessment.time = time;
            } else {
                this.message = "You can add up to 5 tests only!";
                this.isVisible = true;
                this.bgc = "red";
                setTimeout(() => {
                    this.isVisible = false;
                }, 5000);
            }
        },
        removeAssessment(assessment) {
            const index = this.placeholders.findIndex((placeholder) => placeholder?.assessment === assessment);
            if (index !== -1) {
                // Set the assessment at the current index to null
                this.placeholders[index].assessment = null;

                // Shift assessments after the removed one to the left
                for (let i = index + 1; i < this.placeholders.length; i++) {
                    // Move the assessment one position to the left
                    this.placeholders[i - 1].assessment = this.placeholders[i]?.assessment;
                    // Clear the current assessment's position
                    this.placeholders[i].assessment = null;
                }
            }
        },
        moveTestUp(test) {
            const index = this.placeholders.findIndex((placeholder) => placeholder?.assessment === test);

            if (index > 0 && index < this.placeholders.length) {
                // Swap the object with the one above it
                [this.placeholders[index - 1], this.placeholders[index]] = [this.placeholders[index], this.placeholders[index - 1]];
            }
        },
        moveDownObject(test) {
            const index = this.placeholders.findIndex((placeholder) => placeholder?.assessment === test);

            // Check if the index is valid and not the last element
            if (index >= 0 && index < this.placeholders.length - 1) {
                // Swap the object with the one below it
                [this.placeholders[index], this.placeholders[index + 1]] = [this.placeholders[index + 1], this.placeholders[index]];
            }
        },
        createCustomQuestionsTest() {
            const timestamp = Date.now();
            this.customAssessment.name = `(${this.project.name}) Custom Assessment #${timestamp}`;
            this.customAssessment.company = this.Store.company_name;
            this.customAssessment.category = "Custom";

            this.customAssessment.questions_list = [];
            this.selectedQuestions.map((question, index) => {
                if (question.name !== null) {
                    const newQuestion = {
                        question_number: index + 1,
                        time: question.time,
                        category: question.category,
                        title: question.name,
                        question: question.question,
                        options: question.options || {},
                    };
                    this.customAssessment.questions_list.push(newQuestion);
                }
            });

            return axios
                .post(`https://server.go-platform.com/uploadAssessment`, this.customAssessment, {
                    withCredentials: true,
                })
                .then((res) => {
                    this.project.assessments.push(res.data.newAs);

                    // Return the project to ensure that the calling function knows when it's done.
                    return this.project;
                })
                .catch((error) => {
                    console.log("Error trying to create custom assessment", error);
                    throw error; // Re-throw error to propagate it to the caller
                });
        },
        async setDuration(id, duration) {
            axios
                .put(`https://server.go-platform.com/uploadAssessment/duration/${id}`, { duration }, { withCredentials: true })
                .then(() => console.log("Duration updated succefusslly !!!!!!!!!"))
                .catch((error) => console.log("Error", error));
        },

        async updateProject() {
            this.project.assessments = [];
            this.placeholders.map(async (assessment) => {
                if (assessment.assessment !== null) {
                    this.project.assessments.push(assessment.assessment);
                    if (!assessment.assessment.duration) {
                        await this.setDuration(assessment.assessment._id, assessment.assessment.time);
                    }
                }
            });
            let data = JSON.stringify({
                project: this.project,
            });
            let config = {
                method: "put",
                maxBodyLength: Infinity,
                url: `https://server.go-platform.com/projects/update`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
                withCredentials: true,
            };

            axios
                .request(config)
                .then(() => {
                    // this.isVisible = true;
                    // setTimeout(() => {
                    //     this.isVisible = false;
                    // }, 5000);
                    this.$router.push({
                        path: "/boards",
                        query: { id: this.project_id },
                    });
                })
                .catch((error) => {
                    console.log(error);
                    alert("there was an arror");
                });
        },
        async PostJob() {
            if (this.project_id) {
                this.updateProject();
                return;
            }
            this.isLoading = true;

            this.project.assessments = [];
            this.placeholders.map(async (assessment) => {
                if (assessment.assessment !== null) {
                    this.project.assessments.push(assessment.assessment);
                    if (!assessment.assessment.duration) {
                        await this.setDuration(assessment.assessment._id, assessment.assessment.time);
                    }
                }
            });
            this.project.screeners = this.Store.screenersQst;
            try {
                if (this.selectedQuestions.length > 0) {
                    await this.createCustomQuestionsTest();
                }
                axios
                    .post(`https://server.go-platform.com/projects/post`, this.project, {
                        withCredentials: true,
                    })
                    .then((response) => {
                        this.$router.push({
                            path: "/boards",
                            query: { id: response.data._id },
                        });
                        this.project = {
                            name: "",
                            jobTitle: "",
                            seniority: "",
                            assessments: [],
                        };
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        console.log({ error });
                        this.isLoading = false;
                    });
            } catch (error) {
                console.error(error);
                this.isLoading = false;
            }
        },
        async fetchProjectAssessments(id) {
            if (id) {
                let config = {
                    method: "get",
                    maxBodyLength: Infinity,
                    url: "https://server.go-platform.com/projects/project",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        id: id,
                    },
                    withCredentials: true,
                };

                await axios
                    .request(config)
                    .then((response) => {
                        this.project = response.data.project;
                        this.searchJobRole = this.project.jobTitle;
                        this.project.assessments.map((assessment, index) => {
                            this.placeholders[index].assessment = assessment;
                        });
                        this.score = response.data.score;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
    },
    watch: {
        async project_id(newVal) {
            await this.fetchProjectAssessments(newVal);
        },
        placeholders: {
            immediate: true, // Trigger the watcher immediately when the component is created
        },
        searchJobRole: {
            handler(newVal) {
                // if (newVal.length > 0) {
                this.searchedJob = this.jobPositions.filter((job) => job.title.toLowerCase().includes(newVal.toLowerCase()));
                // }
            },
        },
    },
    computed: {
        totalDration: {
            get() {
                return this.placeholders.reduce((acc, placeholder) => {
                    if (placeholder?.assessment !== null) {
                        if (placeholder?.assessment?.questions_nbr > 25) {
                            return acc + parseInt((20 * 35) / 60);
                        } else {
                            return acc + parseInt((placeholder?.assessment?.questions_nbr * 35) / 60);
                        }
                    }
                    return acc;
                }, 0);
            },
        },
    },
    mounted() {
        this.project_id = this.$route.query.id;
        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: "https://server.go-platform.com/jobPositions/all",
            headers: {},
            withCredentials: true,
        };

        axios
            .request(config)
            .then((response) => {
                this.jobPositions = response.data.data;
                this.searchedJob = response.data.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },
};
</script>

<style scoped lang="scss">
:deep(.popper) {
    background: #2371b6;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    max-width: 300px;
}

.newAssessWrapper {
    width: 85%;
    padding: 1% 5%;
    margin: 0 auto; // margin-left: -4%;
    // margin-top: 1%;
    margin-bottom: 3%;
    background-color: #fff;
    height: max-content;

    .backBtn {
        height: 50px;

        border-radius: 10px;
    }

    .projData {
        font-size: 20px;
        font-weight: 700;
    }

    .nextStep {
        width: 100px;
        height: 50px;
        color: white;
        font-weight: 500;
        background: #2196f3;
        border-radius: 10px;

        &:hover {
            opacity: 0.8;
        }
    }

    .previousStep {
        width: 150px;
        height: 50px;
        background-color: #ededed;
        border: 1px solid #ededed;
        border-radius: 10px;
    }

    .steps {
        margin-top: 6%;
        margin-bottom: 8%;
        position: relative;

        .circle {
            width: 40px;
            height: 40px;
            border-radius: 50px;
            background-color: #2371b65e;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: 3px solid #fff;
            }

            &:hover {
                background-color: #2371b6;
            }
        }

        > :first-child {
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 10%;
                width: 32%;
                height: 2px;
                background-color: #2371b65b;
                z-index: -1;
            }
        }

        > :nth-child(2) {
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 60%;
                width: 32%;
                height: 2px;
                background-color: #2371b65b;
                z-index: -1;
            }
        }
    }
}

.options {
    position: absolute;
    top: 110%;
    left: 0;
    height: fit-content;
    max-height: 32.5vh;
    overflow-y: scroll;
    width: 100%;
    margin: 0;
    padding: 5px 0;
    list-style: none;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #ccd7ec;
    box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
    z-index: 1000 !important;
}

.options li {
    padding: 15px 20px;
    cursor: pointer;
    color: #05152e;
}

.options li:hover {
    background-color: #d5efff;
}

.input_group {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    //padding: 0 1rem;
    margin: 0.5rem 0;
    //width: 40rem;
    height: 3rem;

    label {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        color: #05152e;

        img {
            margin-right: 5px;
        }
    }

    // .required {
    //     &::after {
    //         content: "*";
    //         color: #ff6969;
    //     }
    // }

    .floating_label {
        position: absolute;
        top: 50%;
        left: 0;
        margin-left: 30px;
        padding: 0;
        transform: translateY(-50%);
        pointer-events: none;
        background: #fff;
        transition: all 0.3s ease;
    }

    .floating_label.active {
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 50px;
        // width: 10%;
        background: #fff;

        transform: scale(0.8) translate(-10%, -170%);
    }

    input:focus + .floating_label {
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 50px;
        // width: 10%;
        background: #fff;

        transform: scale(0.8) translate(-10%, -170%);
    }

    input,
    textarea {
        border: 1px solid #ccd7ec;
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 8px;
        width: 100%;
        padding: 8px 1.5rem;
    }

    input {
        height: 3rem;
    }

    textarea {
        resize: none;
    }

    .select_label {
        // position: absolute;
        // top: 50%;
        // left: 1%;
        // z-index: 30;
        // margin-left: 5px;
        padding-left: 1rem;
        // transform: translateY(-50%);
        pointer-events: none;
        background: #fff;
    }
}

.custom-select-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.custom-select-arrow {
    position: absolute;
    right: 20px;
    /* Adjust this to position the arrow correctly */
    font-size: 16px;
    /* Adjust the size of the arrow */
    color: #05152e;
    /* Adjust the color of the arrow */
    pointer-events: none;
    /* Make sure the arrow doesn't block clicks */
}

.select {
    height: 3rem;
    // margin: 1%;
    background-color: #fff;
    //padding: 0.6rem;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .selectStatus {
        height: 100%;
        width: 100%;
        background-color: white;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        color: #05152e;
        border: none;
        padding-right: 30px;
        /* Make space for the custom arrow */
        appearance: none;
        /* Remove default arrow */
        -webkit-appearance: none;
        /* Remove default arrow in Chrome */
        -moz-appearance: none;
        /* Remove default arrow in Firefox */

        &:focus {
            outline: none;
        }

        option {
            margin: 0.5rem 2rem;
            font-weight: 500;
            font-size: 18px;
            line-height: 32px;
            color: #05152e;

            &:hover {
                color: #fff;
                background-color: #2196f3;
            }
        }
    }
}

.myTests {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1.5rem;
}

.err_msg {
    color: #ff6969;
    font-size: 14px;
    font-weight: 300;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 125%;
    left: 0;
    // z-index: 30;
    padding: 0 1% 0 0;
    margin-left: 25px;
    transform: translateY(-50%);
    pointer-events: none;
}

.custom-select {
    position: relative;
    // width: 200px;
    border-bottom: 1px solid #ccc;
    border-radius: 8px;
    overflow: visible;
    cursor: pointer;
    height: fit-content;
    border: 1px solid #ccd7ec;
    box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);

    input {
        border: none;
        box-shadow: none;
        width: 100%;
        height: 3rem;

        &:focus {
            border: none;
            outline: none;
        }
    }

    .floating_label {
        margin-left: 0px;
    }
}

.selected-option {
    // padding: 8px;
}

/* Add this CSS to your stylesheet */
.hover-div:hover .span-hover {
    color: white;
    /* Change text color of the span on hover of the container div */
}

.jobPosSearch {
}

.qstDown {
    height: fit-content;
    transition: all 700ms ease;
}

.w-fit {
    width: max-content;
}

.bg-c {
    background: linear-gradient(90deg, rgb(123, 42, 255) 0%, rgb(0, 174, 240) 100%);
}

.radio-container {
    margin-top: 2%;
    margin-bottom: 10px;
    border: 1px solid #000000;
    /* Border color */
    padding: 16px 16px;
    /* Adjust padding as needed */
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
}

/* Hover effect */
.radio-container:hover {
    background-color: #ffffff;
    /* Background color on hover */
}

.checked {
    background-color: #fff;
    color: #000000;
    border: none;
}

.custom-radio:checked {
    background-color: #fff;
    /* Background color when checked */
    color: #ffffff;
    /* Text color when checked */
    border: 1px solid #2196f3;

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        background-color: #2196f3;
        border-radius: 50%;
    }
}

.disabled {
    border: 2px solid #d3d3d3;
    // A4A09E
}

.screenersTitle {
    color: #1b2559;
    font-family: DM Sans;
    // font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 160% */
    letter-spacing: -0.4px;
    width: 90%;
}
</style>
