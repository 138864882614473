<template>
    <div class="container">
        <div class="instructions">
            <span>Instructions</span>
            <div>
                <ul>
                    <li>Use a mix of uppercase and lowercase letters, numbers, and symbols.</li>
                    <li>Avoid using personal information such as your name, birthdate, or address.</li>
                    <li>Use a unique password for each account you have.</li>
                    <li>Use a password that is at least 12 characters long.</li>
                    <li>Consider using a password manager to generate and store your passwords securely.</li>
                    <li>Do not share your password, as it can compromise the security of your account and personal information.</li>
                </ul>
            </div>
        </div>
        <div class="new_pass">
            <img loading="lazy" decoding="async" src="../assets/Images/icon_security_lock.svg" width="250" height="200" alt="" />
            <form action="POST">
                <div class="input_group pad_right_0">
                    <label for="new_password">New Password</label>
                    <div>
                        <input :type="showPassword ? 'text' : 'password'" name="new_password" id="new_password" />
                        <button @click.prevent="showPassword = !showPassword">
                            <img loading="lazy" decoding="async" :src="require(`../assets/${showPassword ? 'icon_eye_closed.svg' : 'icon_eye.svg'}`)" width="25" height="25" alt="" />
                        </button>
                    </div>
                </div>
                <div class="input_group pad_right_0">
                    <label for="confirm_password">Confirm Password</label>
                    <div>
                        <input :type="showPassword ? 'text' : 'password'" name="confirm_password" id="confirm_password" />
                        <button @click.prevent="showPassword = !showPassword">
                            <img loading="lazy" decoding="async" :src="require(`../assets/${showPassword ? 'icon_eye_closed.svg' : 'icon_eye.svg'}`)" width="25" height="25" alt="" />
                        </button>
                    </div>
                </div>
                <div class="continue">
                    <a href="">Need Help?</a>
                    <button type="submit">Continue</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: "NewPassword",
    data() {
        return {
            showPassword: false,
        };
    },
};
</script>

<style lang="scss" scoped>
.container {
    padding: 0 25%;
    color: #343637;
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    grid-template-areas: "instruction form";
    padding: 2rem 4rem;
    gap: 2rem;
    > * {
        background: #fff;
        border-radius: 20px;
        text-align: left;
    }
    .instructions {
        grid-area: instruction;
        padding: 3rem 1rem 0;
        > :first-child {
            font-weight: 700;
            font-size: 30px;
            //line-height: 32px;
        }
        ul {
            font-weight: 500;
            font-size: 18px;
            line-height: 30px;
        }
    }
    .new_pass {
        grid-area: form;
        display: flex;
        flex-direction: column;
        //width: 50%;
        align-items: center;
        justify-content: center;
        padding: 5% 0 0;
        form {
            width: 70%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: center;
            .continue {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-end;
                gap: 1rem;
                align-items: center;
                width: 100%;
                padding: 1rem 0;
                button {
                    font-weight: 600;
                    font-size: 15.3075px;
                    line-height: 23px;
                    color: #fff;
                    background: #2196f3;
                    border: none;
                    border-radius: 7px;
                    padding: 0.5rem 1rem;
                    cursor: pointer;
                }
                a {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 15px;
                    text-decoration: none;
                    color: #2196f3;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .input_group {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            padding: 0 1rem;
            width: 100%;

            label {
                display: flex;
                font-weight: 500;
                font-size: 18px;
                line-height: 32px;
            }

            input {
                border: 1px solid #e5e5ef;
                box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
                border-radius: 4px;
                width: 100%;
                height: 2.5rem;
                padding: 0 0.5rem;
            }
            div {
                display: flex;
                align-items: center;
                border: 1px solid #e5e5ef;
                height: 2.5rem;
                border-radius: 4px;
                background: #fff;
                box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.1);

                button {
                    width: auto;
                    height: fit-content;
                    background: none;
                    border: none;
                }
                input {
                    border: none;
                }
            }
        }
    }
}
input:hover {
    outline: none;
}
</style>
