<template>
    <div class="">
        <OutSourceBanner />
        <TalentAquisition />
        <HowItWorks />
        <WhatWeDo />
        <RecruitmentComponent />
        <HowToGetStarted />
    </div>
</template>

<script>
import OutSourceBanner from "@/components/OutSourceBanner.vue";
import TalentAquisition from "@/components/TalentAquisition";
import HowItWorks from "@/components/HowItWorks.vue";
import WhatWeDo from "@/components/WhatWeDo.vue";
import RecruitmentComponent from "@/components/RecruitementComponent.vue";
import HowToGetStarted from "@/components/HowToGetStarted.vue";
export default {
    name: "OutSourcing",
    components: {
        OutSourceBanner,
        TalentAquisition,
        HowItWorks,
        WhatWeDo,
        RecruitmentComponent,
        HowToGetStarted,
    },
    data() {
        return {
            outsourcing: [
                { name: "Outsourcing", path: "/Outsourcing", icon: "fa-solid fa-globe " },
                { name: "BPO and outsourcing", path: "/BPO-and-outsourcing", icon: "fa-solid fa-globe " },
                { name: "IT services", path: "/assessments-library/IT-services", icon: "fa-solid fa-headset " },
            ],
        };
    },
};
</script>
